body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flg-phone:hover {
  border-bottom: 2px solid var(--joy-palette-text-primary);
}

.flg-phone:focus {
  border: none !important;
  border-bottom: 2px solid #173de5 !important;
  box-shadow: none !important;
}
.flg-phone-error:hover {
  border-bottom: 2px solid var(--joy-palette-error-main) !important;
}

.flg-phone-error:focus {
  border: none !important;
  border-bottom: 2px solid var(--joy-palette-error-main) !important;
  box-shadow: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.OtpInputContainer {
  gap: 3px;
}

.OtpInput {
  height: 56px;
  width: 48px !important;
  border: 1px solid #e0deda;
  font-size: 32px;
  border-radius: 4px;
  background-color: transparent;
}

@media (max-width: 767px) {
  .OtpInputContainer div {
    flex: 1;
  }

  .OtpInput {
    height: 50px;
    width: 100% !important;
  }
}
